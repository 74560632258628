<template>
  <div class="systemConfig">
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
      <a-form-item :label="$t('sysAdmin.rma_id')">
        <a-input v-model:value="rma_id" :placeholder="$t('sysAdmin.rma_id')" style="width: 200px" />
      </a-form-item>
      <a-form-item :label="$t('sysAdmin.start_date')">
        <a-date-picker v-model:value="start_date" valueFormat="YYYY-MM-DD" :placeholder="$t('sysAdmin.start_date')" style="width: 200px" />
      </a-form-item>
      <a-form-item :label="$t('sysAdmin.end_date')">
        <a-date-picker v-model:value="end_date" valueFormat="YYYY-MM-DD" :placeholder="$t('sysAdmin.end_date')" style="width: 200px" />
      </a-form-item>
      <a-form-item :label="$t('sysAdmin.limit')">
        <a-input v-model:value="limit" :placeholder="$t('sysAdmin.limit')" style="width: 200px" />
      </a-form-item>
      <a-form-item :label="$t('sysAdmin.valid_date_of_pruchase')">
        <a-switch v-model:checked="valid_date_of_pruchase" :placeholder="$t('sysAdmin.valid_date_of_pruchase')" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="clearOrders">{{ $t("sysAdmin.clear") }}</a-button>
      </a-form-item>
    </a-form>
    <div class="full_screen" v-if="loading">
      <a-spin size="large" class="spin" />
    </div>
  </div>
</template>
<script>
import { ref, onMounted, createVNode } from "vue";
import useRmaRepositories from "@/composables/useRmaRepositories";
import { useI18n } from "vue-i18n";
import { notification, Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

export default {
  name: "OrderClear",
  components: {},
  data() {
    return {};
  },
  setup() {
    const { getClearOrderStatus } = useRmaRepositories();
    const { t, locale } = useI18n({ useScope: "global" });
    const loading = ref(false);
    const rma_id = ref("");
    const start_date = ref("");
    const end_date = ref("2022-08-01");
    const limit = ref("");
    const valid_date_of_pruchase = ref(false);
    onMounted(() => {});

    const clearOrders = () => {
      if (!end_date.value) {
        notification.error({
          message: "Error",
          description: t("sysAdmin.EndDateIsRequired"),
        });
        return
      }
      Modal.confirm({
        title: t("sysAdmin.DoYouWantToClearOrder"),
        icon: createVNode(ExclamationCircleOutlined),
        async onOk() {
          loading.value = true;
          const result = await getClearOrderStatus(rma_id.value, start_date.value, end_date.value, limit.value, valid_date_of_pruchase.value);
          loading.value = false;
          if (result.success == true) {
            notification.success({
              message: "Success",
              description: `RMA Update`,
            })
          } else {
            notification.error({
              message: "Error",
              description: result.message,
            })
          }
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    };

    return {
      clearOrders,
      loading,
      t,
      locale,
      rma_id,
      start_date,
      end_date,
      limit,
      valid_date_of_pruchase,
    };
  },
};
</script>

<style lang="scss" scoped>
.systemConfig {
  padding: 10px;
  width: 100%;
  min-height: 100%;
  position: relative;

  .full_screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.3);

    .spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}
</style>